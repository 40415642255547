export const getDescuentoLabel = (tipo) => {
  if (tipo === 'DESCUENTO_PRECIO') return 'Descuento (en €)'
  if (tipo === 'DESCUENTO_PORCENTAJE') return 'Descuento (en %)'
  if (tipo === 'HORAS_GRATIS') return 'Horas gratis'
  return ''
}

export const getCompatibilidadTipo = (tipo, compatibilidad)=> {
  if (tipo === "SEGUIMIENTO") return true
  if (tipo === "DESCUENTO_PRECIO") return true
  if (tipo === "DESCUENTO_PORCENTAJE") return compatibilidad.every(c=> ['TARIFA', 'INTENSIVO', 'PACK'].includes(c))
  if (tipo === "HORAS_GRATIS") return compatibilidad.every(c=> c === "TARIFA")    
  return false
}